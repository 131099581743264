











import { defineComponent } from '@nuxtjs/composition-api';
import RenderContent from '~/cms/RenderContent.vue';

export default defineComponent({
  name: 'MarketingCardContainer',
  components: { RenderContent },
  props: {
    marketingCards: {
      type: Array,
      required: true
    }
  }
});
